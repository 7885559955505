<template>
    <Layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader :title="title" />
            </div>
            <div class="col-auto">
                <router-link to="/client" class="btn btn-theme">
                    <i class="mdi mdi-arrow-left font-size-14 align-middle me-2"></i>
                    Back to User List
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-4">
                        <div class="mainButtonEdit">
                            <router-link :to="`/edit-user/${encode(user.id)}`"
                                class="editButtonIcon buttonPosEdit"><i class="mdi mdi-pencil"></i></router-link>
                        </div>
                        <div class="row">
                            <div class="col-md-6 border-end">
                                <div class="avatar-lg mb-3 mx-auto">
                                    <img :src="`https://ui-avatars.com/api/?name=${String(user.firstName).charAt(0).toUpperCase() + String(this.user.lastName).charAt(0).toUpperCase()}&background=3aafa9&size=200`"
                                        alt="" class="img-thumbnail fullCoverImage rounded-circle" />
                                </div>
                                <div class="card-user-info text-center">
                                    <h2>{{ user.firstName }} {{ user.lastName }}</h2>
                                    <div class="mainMailStyle">
                                        <p class="text-muted font-size-14">{{ user.email }}</p>
                                        <div class="iconMainQuestion" v-if="emailNote">
                                            <span class="iconQuestion"><i class="bx bx-question-mark"></i></span>
                                            <div class="contentAvialAble">
                                                <p> user email changed from <span class="text-theme">{{
                                                    emailNote.old_email }}</span> to <span class="text-theme">{{
                                                            emailNote.new_email }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="taskcountinfo border-top pt-3 mb-0">
                                    <h2>Notes:</h2>
                                    <p class="text-center">{{ user.notes }}</p>
                                </div> -->
                            </div>
                            <div class="col-md-6">
                                <div class="main-group-info">
                                    <div class="inner-group-info border-bottom pb-2 mb-2">
                                        <label class="text-muted">Email:</label>
                                        <h3 class="card-title font-weight-500 mb-0">{{ user.email }}</h3>
                                    </div>
                                    <!-- <div class="inner-group-info border-bottom pb-2 mb-2">
                                        <label class="text-muted">Shared Email:</label>
                                        <h3 class="card-title font-weight-500 mb-0">{{ user.sharedEmail }}</h3>
                                    </div> -->
                                    <!-- <div class="inner-group-info border-bottom pb-2 mb-2">
                                        <label class="text-muted">Shared Email Password:</label>
                                        <h3 class="card-title font-weight-500 mb-0">{{ user.sharedEmailPassword }}
                                        </h3>
                                    </div> -->
                                    <!-- <div class="inner-group-info border-bottom pb-2 mb-2">
                                        <label class="text-muted">Phone:</label>
                                        <h3 class="card-title font-weight-500 mb-0">{{ user.phone }}</h3>
                                    </div> -->
                                    <!-- <div class="inner-group-info border-bottom pb-2 mb-2">
                                        <label class="text-muted">Date:</label>
                                        <h3 class="card-title font-weight-500 mb-0" v-if="user.date">{{ `${(new Date(user.date).getUTCMonth() + 1).toString().padStart(2, '0')}-${new Date(user.date).getUTCDate().toString().padStart(2, '0')}-${new Date(user.date).getUTCFullYear()}` }}</h3>
                                         -->
                                        <!-- <h3 class="card-title font-weight-500 mb-0" v-if="user.date">{{ `${(new Date(user.date).getMonth() + 1).toString().padStart(2, '0')}-${new Date(user.date).getDate().toString().padStart(2, '0')}-${new Date(user.date).getFullYear()}` }}</h3> -->
                                    <!-- </div> -->
                                    <!-- <div class="inner-group-info">
                                        <label class="text-muted">Address:</label>
                                        <h3 class="card-title font-weight-500 mb-0">{{ user.street }}, {{ user.city
                                            }},
                                            {{ user.state }} {{ user.zipCode }}</h3>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import "vue3-carousel/dist/carousel.css";
import "vue3-easy-data-table/dist/style.css";
import axios from "axios";
import CryptoJS from "crypto-js";
export default ({
    page: {
        title: "User Information",
        meta: [
            {
                name: "description",
                content: 'description',
            },
        ],
    },
    data() {
        return {
            title: "User Information",
            url: process.env.VUE_APP_URL,
            dataFetched: false,
            user: {},
            id: null,
        }
    },
    components: {
        Layout,
        PageHeader,
    },
    beforeMount() {
        if (!this.dataFetched) {
            this.getUser()
        }
    },
    activated() {
        if (this.dataFetched) {
            this.getUser()
        }
    },
    watch: {
        $route(to, from) {
            if ((to.params.id !== from.params.id)) {
                this.getUser()
            }
        }
    },
    methods: {
        getUser() {
            axios.get(this.url + 'api/admins/' + this.decode(this.$route.params.id)).then(res => {
                this.user = res.data
                this.dataFetched = true
            })
        },
        decode(id) {
            return decodeURIComponent(CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(CryptoJS.enc.Utf8));
        },
        encode(id) {
            return encodeURIComponent(CryptoJS.AES.encrypt(String(id), "Secret Passphrase"));
        }
    },
})
</script>
<style scoped>
.carousel__slide {
    padding: 10px;
}
.carousel__item {
    width: 100%;
}
.mainMailStyle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}
.iconMainQuestion {
    position: relative;
    margin-top: 2px;
}
.iconQuestion {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #3aafa9;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
}
.contentAvialAble {
    position: absolute;
    top: 20px;
    width: 260px;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.92);
    padding: 10px;
    border-radius: 8px;
    color: #fff;
    min-width: 260px;
    transform: translateX(-50%);
    display: none;
    z-index: 999;
}
.iconMainQuestion:hover .contentAvialAble {
    display: block;
}
</style>